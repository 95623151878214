<template>
  <div>
    <!-- float button add -->
    <div class="btn-scroll-to-top">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-b-toggle.sidebar-right
        variant="primary"
        size="lg"
        class="btn-icon rounded-circle"
      >
        <feather-icon icon="PlusIcon" size="16" />
      </b-button>
    </div>
    <b-alert v-height-fade show dismissible fade class="mb-2" variant="primary">
      <div class="alert-body">
        <span>Klik tombol + untuk melakukan pengajuan ijin sakit !</span>
      </div>
    </b-alert>
    <b-card title="Pencarian Data Ijin Sakit Karyawan">
      <b-row>
        <b-col md="12">
          <b-row>
            <b-col md="2" sm="12">
              <b-form-group>
                <v-select
                  v-model="statusReff"
                  :disabled="isSpinner"
                  placeholder="Pilih Status"
                  label="statusName"
                  :options="status"
                />
              </b-form-group>
            </b-col>
            <b-col md="2" sm="12">
              <b-form-group>
                <v-select
                  v-model="month_periode"
                  :disabled="isSpinner"
                  placeholder="Pilih Bulan"
                  label="monthName"
                  :options="monthPeriode"
                />
              </b-form-group>
            </b-col>
            <b-col md="2" sm="12">
              <b-form-group>
                <v-select
                  v-model="year_periode"
                  :disabled="isSpinner"
                  placeholder="Pilih Tahun"
                  label="year"
                  :options="yearPeriode"
                />
              </b-form-group>
            </b-col>
            <b-col md="3" sm="12">
              <b-form-group>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  block
                  @click="getSickLeavesEmployee()"
                >
                  <b-spinner
                    v-show="isSpinner"
                    class="ml-auto"
                    :hidden="!isSpinner"
                    small
                    type="grow"
                  />
                  <div :hidden="isSpinner">
                    <feather-icon icon="SearchIcon" class="mr-50" />
                    <span class="align-middle">Search</span>
                  </div>
                </b-button>
              </b-form-group>
            </b-col>
            <b-col md="3" sm="12">
              <b-form-group>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  block
                  @click="resetFilter()"
                >
                  Reset
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <!-- is data null -->
    <div class="pricing-free-trial" v-if="isNull">
      <b-row>
        <b-col lg="10" offset-lg="3" class="mx-auto">
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-3">
              <h3 class="text-primary">Data ijin sakit tidak ditemukan!</h3>
              <h5>Klik tombol + untuk melakukan pengajuan ijin sakit.</h5>
            </div>

            <!-- images -->
            <b-img
              fluid
              :src="
                require('@/assets/images/illustration/pricing-Illustration.svg')
              "
              class="pricing-trial-img"
              alt="svg img"
            />
            <!--/ images -->
          </div>
        </b-col>
      </b-row>
    </div>
    <!--/ is data null -->

    <!-- card cuti -->
    <b-overlay
      :show="isOverlay"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-row v-if="isTableShow">
        <b-col md="4" sm="12" v-for="sleave in sickleaves" :key="sleave.sick_leave_code">
          <b-card no-body>
            <b-card-header class="align-items-baseline">
              <div>
                <b-card-title class="mb-1">{{ sleave.sick_leave_code }}</b-card-title>
                  <b-card-sub-title class="mb-1 font-italic">
                  Dibuat pada {{ timeAgo(sleave.created_at) }}
                  </b-card-sub-title>
                <b-card-sub-title>
                  <b-badge
                    :variant="status_kode[1][sleave.status]"
                  >
                  {{ status_kode[0][sleave.status] }}
                  </b-badge> - 
                  <b-badge 
                    href="#"
                    variant="light-dark"
                    user="'sleave'"
                    @click="showAttch(sleave)"
                  >
                    <feather-icon
                      icon="FileIcon"
                      class="mr-25"
                    /> Lampiran 
                  </b-badge>     

                </b-card-sub-title>
              </div>

              <b-dropdown
                variant="link"
                no-caret
                right
                class="chart-dropdown"
                toggle-class="p-0"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="18"
                    class="text-body cursor-pointer"
                  />
                </template>
                <b-dropdown-item 
                href="#" 
                @click="onClickUpdate(sleave)"
                :disabled="sleave.status_text !== 'PROPOSED'"
                >
                  Ubah Data
                </b-dropdown-item>
                <b-dropdown-item 
                href="#" 
                @click="confirmText(sleave)"
                :disabled="sleave.status_text !== 'PROPOSED'"
                > 
                  Batal 
                </b-dropdown-item>
              </b-dropdown>
            </b-card-header>

            <b-card-body>
              <b-card-text>
                Mengajukan ijin beristirahat selama
                <strong>{{
                  (new Date(sleave.periode_end).getTime() -
                    new Date(sleave.periode_start)) /
                    (1000 * 3600 * 24) +
                  1
                }}</strong>
                hari, pada
                <strong>{{ stdDate(sleave.periode_start) }}</strong>
                sampai dengan
                <strong>{{ stdDate(sleave.periode_end) }}</strong> dikarenakan
                 <strong>{{ sleave.employee_notes }}</strong
                >.
              </b-card-text>

              <b-card-text class="font-italic" v-if="sleave.updated_at != null">
                Diperiksa pada {{ longDate(sleave.updated_at) }}
              </b-card-text>
              <b-card-text class="font-italic" v-else>
                Belum diperiksa
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
    <!-- / card cuti -->
    <b-modal
      ref="v-modal"
      id="v-modal"
      ok-variant="secondary"
      modal-class="modal-secondary"
      hide-footer
      centered
      size="lg"
      title="Lampiran"
    >
      <b-embed frameborder="0" type="iframe" aspect="16by9" width="100%" :src="file.file_dir" />
    </b-modal>
    <b-modal
      ref="u-modal"
      ok-variant="secondary"
      modal-class="modal-secondary"
      hide-footer
      centered
      size="lg"
      title="Ubah Data Surat Sakit"
    >
        <b-form
          class="p-2"
          @submit.prevent="SaveEditSickLeaves()"
        >
          <b-form-input
          v-model="employee_code"
          name="employee_code"
          ref="employee_code"
          id="employee_code"
          hidden
          />
          <b-form-group
            label="Tanggal Periode Awal"
            label-for="periode_start"
          >
            <flat-pickr
              id="periode_start"
              v-model="periode_start"
              class="form-control"
              placeholder="Pilih Tanggal"
            />
          </b-form-group>
          <b-form-group
            label="Tanggal Periode Akhir"
            label-for="periode_end"
          >
            <flat-pickr
              id="periode_end"
              v-model="periode_end"
              class="form-control"
              placeholder="Pilih Tanggal"
            />
          </b-form-group>
          <b-form-group
            label="Catatan"
            label-for="employee_notes"
          >
            <b-form-textarea
              id="employee_notes"
              v-model="employee_notes"
              rows="3"
              placeholder="Ketik Alasan Cuti"
              class="mb-2"
            />
          </b-form-group>
          <b-form-group>
            <b-card-text class="my-1">
              File : <strong>{{ file_name }}</strong>
            </b-card-text>
          </b-form-group>
          <b-form-group
            label="Lampiran"
            label-for="attachment_file"
          >
            <b-form-file
              id="attachment_file"
              v-model="attachment_file"
              accept="image/jpeg, application/pdf"
              placeholder="Klik disini untuk memilih file"
              @change="onChange"
            />

            <b-card-text class="my-1">
              Ukuran File : <strong>{{ attachment_file ? (attachment_file.size / 1048576).toFixed(2) + ' MB': ''  }} / 3 MB</strong>
            </b-card-text>
          </b-form-group>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              block
            >
              <b-spinner
                v-show="isSpinner"
                class="ml-auto"
                :hidden="!isSpinner"
                small
                type="grow"
              />
              <div :hidden="isSpinner">
                <span class="align-middle">Simpan</span>
              </div>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="closeModal()"
            >
              Batal
            </b-button>
          </div>
        </b-form>
    </b-modal>
    <b-modal
      ref="e-modal"
      ok-variant="danger"
      modal-class="modal-danger"
      hide-footer
      centered
      title="Peringatan !"
    >
      <b-card-text> {{ message }} </b-card-text>
    </b-modal>

    <!-- cuti sidebar -->
    <add-sick-sidebar />
    <!-- <update-cuti-sidebar :LeavesCode="LeavesCode" /> -->
  </div>
</template>

<script>
import {
  BAlert,
  BModal,
  BBadge,
  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BCardHeader,
  BEmbed,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BSpinner,
  BOverlay,
  BImg,
  BTable,
  BPagination,
  BInputGroup,
  BFormSelect,
  BFormInput,
  BForm,
  BFormInvalidFeedback,
  BFormDatepicker,
  BFormTextarea,
  BFormFile,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  VBToggle,
  VBModal,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { heightFade } from "@core/directives/animations";
import axios from "axios";
import AddSickSidebar from "./AddSickSidebar.vue";
import moment from "moment";
import "moment/locale/id";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BAlert,
    BModal,
    BBadge,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BCardHeader,
    BEmbed,
    vSelect,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BOverlay,
    BImg,
    BFormSelect,
    BPagination,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BFormDatepicker,
    BFormTextarea,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BTable,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    AddSickSidebar,
    flatPickr,
  },
  directives: {
    Ripple,
    heightFade,
    "b-toggle": VBToggle,
    'b-modal': VBModal,
  },
  data() {
    return {
      isOverlay: false,
      isNull: false,
      isTableShow: false,
      isSpinner: false,
      isLoadingHideButton: true,
      isDisableByNotSelected: true,
      isDisableByVerified: true,
      status: [],
      year_periode: [],
      month_periode: [],
      statusReff: [],
      file: '',
      monthPeriode: [
        {
          monthCode: "01",
          monthName: "Januari",
        },
        {
          monthCode: "02",
          monthName: "Februari",
        },
        {
          monthCode: "03",
          monthName: "Maret",
        },
        {
          monthCode: "04",
          monthName: "April",
        },
        {
          monthCode: "05",
          monthName: "Mei",
        },
        {
          monthCode: "06",
          monthName: "Juni",
        },
        {
          monthCode: "07",
          monthName: "Juli",
        },
        {
          monthCode: "08",
          monthName: "Agustus",
        },
        {
          monthCode: "09",
          monthName: "September",
        },
        {
          monthCode: "10",
          monthName: "Oktober",
        },
        {
          monthCode: "11",
          monthName: "November",
        },
        {
          monthCode: "12",
          monthName: "Desember",
        },
      ],
      yearPeriode: [
        {
          yearCode: "2022",
          year: "2022",
        },
        {
          yearCode: "2023",
          year: "2023",
        },
        {
          yearCode: "2024",
          year: "2024",
        },
        {
          yearCode: "2025",
          year: "2025",
        },
        // {
        //   yearCode: "2026",
        //   year: "2026",
        // },
        // {
        //   yearCode: "2027",
        //   year: "2027",
        // },
        // {
        //   yearCode: "2028",
        //   year: "2028",
        // },
        // {
        //   yearCode: "2029",
        //   year: "2029",
        // },
        // {
        //   yearCode: "2030",
        //   year: "2030",
        // },
      ],
      employeeData: JSON.parse(localStorage.getItem("userData")),
      sickleaves: [],
      status_kode: [
        {
          "SLV-00": "PROPOSED",
          "SLV-01": "APPROVED",
          "SLV-02": "VERIFIED",
          "SLV-03": "CANCELED",
          "SLV-04": "REJECTED",
        },
        {
          "SLV-00": "light-warning",
          "SLV-01": "light-info",
          "SLV-02": "success",
          "SLV-03": "light-secondary",
          "SLV-04": "light-danger",
        },
      ],
      usLeave: [],
      periode_start: null,
      periode_end: null,
      employee_notes: null,
      attachment_file: null,
      file_name: null,
      employee_code: null,
      SickLeavesCode: [],
      message: '',
    };
  },
  created() {
    this.getSickLeavesEmployee();
    this.getSickLeavesStatusReff();
  },
  mounted() {
    this.intervalFetchData();
  },
  methods: {
    timeAgo(date) {
      moment.locale("id");

      return moment(date).fromNow();
    },
    longDate(date) {
      moment.locale("id");

      return moment(date).format("dddd, DD MMMM  YYYY h:mm a");
    },
    stdDate(date) {
      moment.locale("id");

      return moment(date).format("dddd, DD MMMM YYYY");
    },
    showAttch(sleave) {
      console.log(sleave);
      //v-b-modal="'v-modal'"
      //this.file = sleave
      if (sleave.file_ext != 'pdf') {
        window.open(sleave.file_dir, '_blank', "height=900,width=900");
      } else {
        this.file = sleave
        this.$refs["v-modal"].show()
      }
    },
    onChange(event){
      this.attachment_file = event.target.files[0]
    },
    resetFilter() {
      this.status = [];
      this.month_periode = [];
      this.year_periode = [];
    },
    resetSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    getSickLeavesStatusReff() {
      axios.get("/sickLeaves/statusReff").then((response) => {
        console.log(response.data);
        this.status = response.data.data;
      });
    },
    intervalFetchData() {
      setInterval(() => {
        this.fetchDataList();
      }, 300000);
    },
    fetchDataList() {
      axios
        .post("/sickLeaves/list", {
          employee_code: this.employeeData.employee_code,
          month_periode: this.month_periode.monthCode,
          year_periode: this.year_periode.year,
          status: this.statusReff.statusKode,
        })
        .then((res) => {
          this.sickleaves = res.data.data;
        });
    },
    getSickLeavesEmployee() {
      this.isTableShow = false
      this.isOverlay = true
      axios
        .post("/sickLeaves/list", {
          employee_code: this.employeeData.employee_code,
          month_periode: this.month_periode.monthCode,
          year_periode: this.year_periode.year,
          status: this.statusReff.statusKode,
        })
        .then((response) => {
          console.log(response.data.data);
          this.sickleaves = response.data.data;
          this.isNull = false;
        })
        .catch((error) => {
          console.log(error.response.status);
          if (error.response.status === 400) {
            console.log(error);
          }
          if (error.response.status === 404) {
            this.isNull = true;
            this.isTableShow = false;
          }
          if (error.response.status === 401) {
            this.$router.push({ name: "not-authorized" }).catch(() => {});
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          this.isOverlay = false
          this.isTableShow = true
        });
    },
    onRowSelected(items) {
      if ((this.selected = items[0])) {
        this.isDisableByNotSelected = false;
        console.log(this.selected);
        if (this.selected.status_text !== "PROPOSED") {
          this.isDisableByVerified = true;
        } else {
          this.isDisableByVerified = false;
          this.SickLeavesCode = this.selected.sick_leave_code;
          console.log(this.LeavesCode);
        }
      } else {
        this.$refs.selectableTable.clearSelected();
        this.isDisableByVerified = true;
        this.isDisableByNotSelected = true;
      }
    },
    viewSelected() {
      //const sickCode = this.selected.sick_leave_code
      axios
        .post("/sickLeaves/detil", {
          sick_leave_code: this.selected.sick_leave_code,
        })
        .then((response) => {
          window.open(
            "http://192.168.1.99/bros_hrs_dev/public/uploads/attachments/sick_leaves/" +
              response.data.data.attachment_file_name,
            "_blank",
            "height=900,width=900"
          );
          console.log(response.data);
        });
    },
    closeModal() {
      this.$refs["u-modal"].hide()
    },
    onClickUpdate(sleave) {
      this.usLeave = sleave.sick_leave_code;
      console.log(this.usLeave);
      this.$refs["u-modal"].show();
      if(this.usLeave !== null) {
        axios.post('sickLeaves/detil', {
        leave_code: this.usLeave
        })
        .then(response => {
          console.log(response);
          this.periode_start = response.data.data.periode_start
          this.periode_end = response.data.data.periode_end
          this.employee_notes = response.data.data.employee_notes
          this.file_name = response.data.data.file_name
        })
      } else {
        console.log(error);
      }
    },
    SaveEditSickLeaves() {
      this.isTableShow = false
      const fdata = new FormData();
      fdata.append('_method', 'patch')
      fdata.append('sick_leave_code', this.usLeave)
      fdata.append('periode_start', this.periode_start)
      fdata.append('periode_end', this.periode_end)
      fdata.append('employee_notes', this.employee_notes)
      fdata.append('attachment_file', this.attachment_file)

      // let config = {
      //     header : {
      //     'Content-Type' : 'multipart/form-data'
      //   }
      // } 

      console.log(fdata);
      //this.isSpinner = true
      
      axios.post('/sickLeaves/update', fdata)
      // .then(res => { console.log(res);})
        .then(response => {
          if (response.data.data.length === 0) {
            console.log(response)
            this.$swal({
              icon: 'success',
              title: 'Data berhasil disimpan',
              timer: 1500,
              showCancelButton: false,
              showConfirmButton: false,
            }).then(
              () => {},
              // handling the promise rejection
              dismiss => {
                if (dismiss === 'timer') {
                  // console.log('I was closed by the timer')
                }
              },
            )
          } else {
            console.log(response)
            this.$refs['e-modal'].show()
            this.isValid = true
            this.message = response.data.message
            // console.log(this.message);
          }
        })
        .catch(error => {
          // console.log(error);
          if (error.response.status === 400) {
            console.log(error.response)
            this.$refs['e-modal'].show()
            this.message = error.response.data.message
          } 
          if (error.response.status === 401) {
          this.$router.push({ name: "not-authorized" }).catch(() => {});
          } 
          else {
            console.log(error)
          }
        })
        .finally(() => {
          // this.isSpinner = false
          // this.resetForm()
          this.$refs['u-modal'].hide()
          this.getSickLeavesEmployee()
          this.isTableShow = true
          this.isNull = false;
          // this.$parent.fetchDataList()
        })
    },
    confirmText(sleave) {
      this.$swal({
        title: "Apa anda yakin?",
        text: "Data yang sudah dibatalkan tidak dapat dikembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isTableShow = false;
          axios
            .patch("/sickLeaves/delete", {
              sick_leave_code: sleave.sick_leave_code,
            })
            .then(() => {
              this.$swal({
                icon: "success",
                title: "Canceled!",
                text: "Cuti anda berhasil dibatalkan.",
                timer: 1500,
              });
              this.resetSelected();
            })
            .catch((error) => {
              console.log(error.response.data.message);
            })
            .finally(() => {
              this.isTableShow = true;
              this.getSickLeavesEmployee();
            });
        }
      });
    },
    ToastDataNotFound() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Data tidak ditemukan.",
          icon: "BellIcon",
          variant: "danger",
        },
      });
    },
    ToastDataFound() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Data ditemukan.",
          icon: "BellIcon",
          variant: "success",
        },
      });
    },
    // validationFormInfo() {
    //   this.$refs.infoRules.validate().then(success => {
    //     if (success) {
    //       this.getLeaveEmployee()
    //     } else {
    //       this.$refs['v-modal'].show()
    //     }
    //   })
    // },
  },
};
</script>

<style lang="scss" scoped>
.btn-scroll-to-top {
  position: fixed;
  bottom: 5%;
  right: 30px;
  z-index: 99;

  // opacity: 0;
  // // transform: translateY(100px);
  // transition: all .5s ease;

  // &.show {
  //   opacity: 1;
  //   // transform: translateY(0)
  // }
}
@import "@core/scss/vue/pages/page-pricing.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
